import React from 'react'
import { cx, css } from 'linaria'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import { ProductItemType } from '@/types'
import { ReactComponent as ButtonDowloadOnTheAppStore } from '@components/svg/download-app-store-white.svg'

const ProductItem = ({ name, shortDesc, bgClass = '', appStoreUrl, detailPath = '#', imageRegex }: ProductItemType) => {
  const data = useStaticQuery(graphql`
    query {
      allMobileLogos: allFile(filter: { absolutePath: { regex: "/product/logo/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 70, height: 70) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
      allDesktopLogos: allFile(filter: { absolutePath: { regex: "/product/logo/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 120, height: 120) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const mobileLogo = data.allMobileLogos.edges.find(({ node }) => {
    return node.relativePath.includes(imageRegex)
  })
  const desktopLogo = data.allDesktopLogos.edges.find(({ node }) => {
    return node.relativePath.includes(imageRegex)
  })
  const sources = [
    mobileLogo.node.childImageSharp.fixed,
    {
      ...desktopLogo.node.childImageSharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ]
  return (
    <div
      className={cx(
        'p-6 md:p-10 h-full',
        css`
          border-radius: 8px;
          min-height: 300px;
          max-width: 560px;
        `,
        'flex flex-col md:flex-row md:justify-between md:items-center',
        bgClass
      )}
    >
      <div
        className={cx(
          'flex-none mb-5 md:mb-0',
          css`
            width: 70px;
            @media (min-width: 1024px) {
              width: 120px;
              height: 120px;
            }
          `
        )}
      >
        <Img fixed={sources} />
      </div>

      <div className="flex flex-grow items-center md:p-4 p-0">
        <div className="text-white">
          <h3
            className={cx(
              'font-header',
              css`
                font-weight: bold;
                font-size: 28px;
                line-height: 28px;
                margin-bottom: 0.75rem;
              `
            )}
          >
            {name}
          </h3>
          <div
            className={css`
              font-size: 18px;
              line-height: 24px;
              margin-bottom: 26px;
            `}
          >
            {shortDesc}
          </div>
          <div className="flex items-center flex-wrap">
            {appStoreUrl && (
              <a
                className={cx(
                  'inline-block hover:opacity-75 focus:opacity-75 mr-2 mb-3',
                  css`
                    height: 34px;
                  `
                )}
                target="blank"
                rel="noopener noreferer"
                href={appStoreUrl}
                title={`Download ${name} on the App Store`}
              >
                <ButtonDowloadOnTheAppStore className="h-full" />
              </a>
            )}
            <Link to={detailPath} title={`Learn more about ${name}`} className="btn btn-default mb-3">
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductItem
