import React from 'react'
import { cx, css } from 'linaria'
import { Link } from 'gatsby'

import MobileBg1 from './mobile-bg-1.svg'
import MobileBg2 from './mobile-bg-2.svg'
import { ReactComponent as SvgBg1 } from './bg1.svg'
import { ReactComponent as SvgBg2 } from './bg2.svg'
import { ReactComponent as SvgBg3 } from './bg3.svg'
import { ReactComponent as SvgBg4 } from './bg4.svg'
import { ReactComponent as SvgBg5 } from './bg5.svg'
import { ReactComponent as SvgBg6 } from './bg6.svg'
import { ReactComponent as SvgBg7 } from './bg7.svg'

export const Header = () => {
  return (
    <section
      className={cx(
        'flex items-center justify-center w-full min-h-screen relative bg-background-light',
        css`
          margin-top: -64px;
          @media (min-width: 768px) {
            margin-top: -80px;
          }
        `
      )}
    >
      <div className="container px-4 text-center z-10">
        <h1 className="text-headline font-header font-900 uppercase leading-tight mb-1">WE DEVELOP WITH PASSION</h1>
        <div className="text-xl font-500 mb-6">We are Superbits and we create super lean softwares.</div>
        <Link
          className="btn btn-primary"
          to="#productBlock"
          onClick={e => {
            e.preventDefault()
            document.querySelector('#productBlock')?.scrollIntoView({ behavior: 'smooth' })
          }}
        >
          See more
        </Link>
      </div>
      <div
        id="bg-wrapper"
        className={cx(css`
          @apply hidden z-0;
          @media (min-width: 768px) {
            display: block;
          }
        `)}
      >
        <SvgBg1 id="shape1" className={cx('absolute top-0 left-0')} />
        <SvgBg2 id="shape2" className={cx('absolute top-0 right-0')} />
        <SvgBg3 id="shape3" className={cx('absolute top-0 right-0 z-10')} />
        <SvgBg4 id="shape4" className={cx('absolute right-0 bottom-0')} />
        <SvgBg5 id="shape5" className={cx('absolute right-0 bottom-0')} />
        <SvgBg6 id="shape6" className={cx('absolute left-0 bottom-0 z-10')} />
        <SvgBg7 id="shape7" className={cx('absolute top-0 left-0')} />
      </div>
      <div
        className={cx(css`
          display: block;
          @media (min-width: 768px) {
            display: none;
          }
        `)}
      >
        <img className="absolute top-0 left-0" src={MobileBg1} alt="background-yellow" />
        <img className="absolute bottom-0 right-0" src={MobileBg2} alt="background-blue" />
      </div>
    </section>
  )
}
