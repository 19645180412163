import React from 'react'
import { cx } from 'linaria'
import { Link } from 'gatsby'

import { productList } from '@/components/contants'
import { ReactComponent as ArrowRightIcon } from '@components/svg/arrow-right.svg'
import ProductItem from '@components/index/product-item'

export const Products = () => (
  <div id="productBlock" className="flex flex-col items-center bg-white px-4 pt-5 md:pt-30">
    <div className="grid grid-cols-1 row-gap-8 sm:grid-cols-2 sm:gap-12">
      {productList
        .filter(item => !item.hideOnHome)
        .map(item => (
          <div key={item.name}>
            <ProductItem {...item} />
          </div>
        ))}
    </div>
    <div className={cx('w-full text-center my-10 md:mt-15 md:mb-30')}>
      <Link className="btn btn-link btn-primary" title="See all of our products" to="/all-products">
        See all of our products
        <ArrowRightIcon className="ml-2 inline-block" />
      </Link>
    </div>
  </div>
)
