import React from 'react'

import { Base } from '@components/ui/base'
import { Header } from '@components/ui/header'
import { Header as Jumbotron } from '@components/index/header'
import { Products } from '@components/index/products'
import { Footer } from '@components/ui/footer'
import Seo from '@components/seo'
import { Page } from 'gatsby'

const IndexPage = ({ path }: Page) => {
  return (
    <>
      <Seo
        title="Superbits - Develop with Passion"
        description="We create super lean software for your next level work performance."
        noAppendTitle={true}
      />

      <Base>
        <div className="relative">
          <Header id="home-header" className="top-0 sticky w-full z-20" bgColor="bg-grey" />
          <Jumbotron />
        </div>
        <Products />
        <Footer path={path} />
      </Base>
    </>
  )
}

export default IndexPage
